import React from 'react';
import { Container, Row } from 'react-bootstrap';

const SalesVolume = () => {
  return (
    <Container className="vh-100 d-flex flex-column ">
      <h3>Объем продаж по датам</h3>
      <Row className="h-100">
        <iframe
          frameBorder="0"
          src="https://datalens.yandex/6v4vi7sati08u?_embedded=1&_no_controls=1&_theme=light"
        ></iframe>
      </Row>
    </Container>
  );
};

export default SalesVolume;
