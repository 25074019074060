import React from 'react';
import './SmartMenu.css';
import getMenuItems from './menuItems';
// import {AppContext} from "@edx/frontend-platform/react";
import MenuElement from './menu-elements/MenuElement';

const SmartMenu = () => {
  // const appConfig = useContext(AppContext).config;
  const appConfig = { LMS_BASE_URL: 'https://lms-smart.ru' };
  const menuItems = getMenuItems(appConfig);

  // useEffect(() => {
  //   // blind version - dodelat
  //   const jqscript = document.createElement('script');
  //   jqscript.src = "https://lidrekon.ru/slep/js/jquery.js";
  //   document.body.appendChild(jqscript);
  //   const script = document.createElement('script');
  //   script.src = "https://lidrekon.ru/slep/js/uhpv-full.min.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  //   return () => {
  //     document.body.removeChild(script);
  //     document.body.removeChild(jqscript);
  //   }
  // }, []);

  return (
    <>
      <div className="menu">
        <ul className="menuUl">
          {menuItems.length ? (
            menuItems.map((menuItem, index) => (
              <MenuElement
                link={menuItem.link}
                image={menuItem.img}
                title={menuItem.title}
                style={menuItem.style || {}}
                key={index}
              />
            ))
          ) : (
            <span>No menu!</span>
          )}
          <li className="spacer"></li>
          {/* правила */}
          <MenuElement
            link={''.concat(appConfig.LMS_BASE_URL, '/rules.html')}
            image={''.concat(
              appConfig.LMS_BASE_URL,
              '/static/smart-design/images/smartCourse/rules_menu.svg'
            )}
            title="Правила"
          />
          {/*версия для слабовидящих https://lidrekon.ru/slep/*/}
          <li className="menuElementLi">
            <a
              id="specialButton"
              href="#"
              className="menuElementA"
              style={{
                transition: 'all 0.3s',
                display: 'inline-block',
                height: 62
              }}
            >
              {/*<div className="gc-frame">*/}
              <img
                src={''.concat(
                  appConfig.LMS_BASE_URL,
                  '/static/smart-design/images/blind.svg'
                )}
                alt="Версия для слабовидящих"
                title="Версия для слабовидящих"
              />
              {/*</div>*/}
              <span className="blind-menu">ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SmartMenu;
