import React, {  useEffect, useState } from 'react';
import './HeaderRightPanel.css';
import messagesIcon from './assets/mail.png';
import notificationsIcon from './assets/tips.png';
import { Dropdown } from 'react-bootstrap';
import {useKeycloak} from "@react-keycloak/web";
import axios from "axios";


const HeaderRightPanel = () => {
  const { keycloak } = useKeycloak();
  const [userImage, setUserImage] = useState(null);

  const username = keycloak.tokenParsed.preferred_username;
  console.log({ keycloak });

  // changePasswUrl = keycloak.changePasswordUrl // как получить
  // /api/user/v1/me",
  const baseUrl = 'https://lms-smart.ru';

  useEffect(() => {
    const fetchUserImage = async () => {
      try {
        // const response = await fetch(
        //   // `${baseUrl}/api/user/v1/accounts/${username}`
        //   `${baseUrl}/api/user/v1/me`,
        // );
        const response = await axios.get(
          // `${baseUrl}/api/user/v1/me`,
          `${baseUrl}/api/user/v1/accounts/${username}`,
          { withCredentials: true }
        );
        const data = response.data;
        setUserImage(data.profile_image.image_url_medium);
      } catch (error) {
        console.error('Error fetching user image:', error);
      }
    };

    fetchUserImage();
  }, [username]);



  return (
    <div className="headerRightPanel">
      <div className="ua-icon">
        <img alt="Сообщения" src={messagesIcon} />
      </div>
      <div className="ua-icon">
        <img alt="Уведомления" src={notificationsIcon} />
      </div>

      {/*<Dropdown drop={'start'} >*/}
      {/*  <Dropdown.Toggle  as='a' >*/}
      {/*    {userImage ? (*/}
      {/*      <div>*/}
      {/*        <img src={userImage} alt="User" style={{ 'border-radius': '50%' }} />*/}
      {/*      </div>*/}
      {/*    ) : (*/}
      {/*      <div className="skeleton-circle search-size" ></div>*/}
      {/*    )}*/}
      {/*  </Dropdown.Toggle>*/}
      {/*  <Dropdown.Menu className='py-2'>*/}
      {/*    <Dropdown.Item href="https://apps.lms-smart.ru/account/">Аккаунт</Dropdown.Item>*/}
      {/*    <Dropdown.Item href="https://apps.lms-smart.ru/orders/orders">Заказы</Dropdown.Item>*/}
      {/*    <Dropdown.Divider />*/}
      {/*    <Dropdown.Item href="https://lms-smart.ru/logout">Выйти</Dropdown.Item>*/}
      {/*  </Dropdown.Menu>*/}
      {/*</Dropdown>*/}

      <Dropdown
        align="end">
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <div>
            <img className="userpic" alt="аватар" src={userImage} />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu}>
          <Dropdown.Item
            eventKey="1"
            href="https://apps.lms-smart.ru/account/"
          >
            Аккаунт
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="2"
            href="https://apps.lms-smart.ru/orders/orders"
          >
            История заказов
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="3" href="https://lms-smart.ru/logout">
            Выйти
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled" style={{ marginBottom: 0 }}>
          {React.Children.toArray(children)}
        </ul>
      </div>
    );
  }
);

export default HeaderRightPanel;
