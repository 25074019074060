import axios from 'axios';

export const getAdminMembers = async (access_token: string) => {
  return await axios.get(
    `${process.env.REACT_APP_KEYCLOAK_URL}/admin/realms/openedx/groups/${process.env.REACT_APP_KK_CHAT_MANAGER_GROUP_UUID}/members`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
        'Access-Control-Allow-Origin': '*'
      }
    }
  );
};
