import paths, { rootPaths } from './paths';
import keycloakRealmRoles from "../helpers/keycloakRealmRoles";

export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      children: [
        {
          name: 'Default',
          to: rootPaths.root,
          exact: true,
          active: true,
          realmRoles: [keycloakRealmRoles.DASHBOARD_STATISTICS]
        },
        {
          name: 'E Commerce',
          to: paths.ecommerce,
          active: true,
          realmRoles: [keycloakRealmRoles.DASHBOARD_STATISTICS]
        },
        {
          name: 'LMS',
          to: paths.lms,
          active: true,
          realmRoles: [keycloakRealmRoles.DASHBOARD_STATISTICS]
        }
      ]
    }
  ]
};
export const appRoutes = {
  label: 'app',
  children: [
    {
      name: 'Chat',
      icon: 'comments',
      to: paths.chat,
      active: true,
      realmRoles: []
    },
    {
      name: 'E Commerce',
      icon: 'shopping-cart',
      active: true,
      children: [
        {
          name: 'Продажи курсов',
          active: true,
          to: '/e-commerce/goods-sales',
          realmRoles: [keycloakRealmRoles.PAYMENT_STATISTICS]
        },
        {
          name: 'Объем продаж по датам',
          active: true,
          to: '/e-commerce/sales-volume',
          realmRoles: [keycloakRealmRoles.PAYMENT_STATISTICS]
        },
        {
          name: 'Выручка по месяцам',
          active: true,
          to: '/e-commerce/revenue-by-month',
          realmRoles: [keycloakRealmRoles.PAYMENT_STATISTICS]
        },
        {
          name: 'Показатели нарастающим итогом по месяцам',
          active: true,
          to: '/e-commerce/indicators-cumulative-total-by-months',
          realmRoles: [keycloakRealmRoles.PAYMENT_STATISTICS]
        },
        {
          name: 'Сводные показатели продаж',
          active: true,
          to: '/e-commerce/summarized-sales-figures',
          realmRoles: [keycloakRealmRoles.PAYMENT_STATISTICS]
        },
      ]
    },
    {
      name: 'Kinescope',
      icon: 'share-alt',
      active: true,
      children: [
        {
          name: 'Структура проектов',
          to: paths.kinescopeFolderStructure,
          active: true,
          realmRoles: []
        },
      ]
    },
    {
      name: 'E Learning',
      icon: 'graduation-cap',
      active: true,
      children: [

      ]
    }
  ]
};

export const pagesRoutes = {
  label: 'pages',
  children: []
};

export const modulesRoutes = {
  label: 'Modules',
  children: []
};

export const documentationRoutes = {
  label: 'documentation',
  children: []
};

export default [
  dashboardRoutes,
  appRoutes,
  /*pagesRoutes,
  modulesRoutes,*/
  /*documentationRoutes*/
];
