import React from 'react';
import {Card, Container} from "react-bootstrap";
import PageHeader from "../../common/PageHeader";
import FalconComponentCard from "../../common/FalconComponentCard";
import FalconEditor from "../../common/FalconEditor";
import Treeview from "../../common/Treeview";
import { kinescopeApi } from "../../../helpers/kinescopeApi";

const ProjectStructures = () => {
  const kinescopeProjects = kinescopeApi.useProjectsListQuery()

  if (kinescopeProjects.isFetched) {
    console.log(kinescopeProjects.data)
    return (
      <>
        <FalconComponentCard>
          {kinescopeProjects.data}
          dfwerwe
        </FalconComponentCard>
      </>
    );
  } else {
    return null;
  }
};

export default ProjectStructures;