import React from 'react';
import { Card, Col, Offcanvas, Row, Container } from 'react-bootstrap';

const GoodsSales = () => {
  return (
    <Container className="vh-100 d-flex flex-column ">
      <h3>Продажи курсов</h3>
      <Row className="h-100">
        <iframe
          frameBorder="0"
          src="https://datalens.yandex/sg480204pevmh?_embedded=1&_no_controls=1&_theme=light&_lang=ru"
        ></iframe>
      </Row>
    </Container>
  );
};

export default GoodsSales;
