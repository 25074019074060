import React from 'react';

export const Profile = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 11.9334C5 10.7552 5.95513 9.80005 7.13333 9.80005H10.8667C12.0449 9.80005 13 10.7552 13 11.9334V11.9334C13 12.5225 12.5224 13 11.9333 13H6.06667C5.47756 13 5 12.5225 5 11.9334V11.9334Z"
        strokeLinejoin="round"
      />
      <circle cx="8.9999" cy="6.6" r="1.6" />
    </svg>
  );
};
