import React from 'react';
import './MenuElement.css';

const MenuElement = ({ link, image, title, style }) => {
  return (
    <li className="menuElementLi">
      <a
        className="menuElementA"
        href={link}
        title={title}
        style={{ transition: 'all 0.3s', display: 'inline-block', height: 62 }}
      >
        <img alt={title} src={image} style={style} />
      </a>
    </li>
  );
};

export default MenuElement;
