import React from 'react';
import { Container, Row } from 'react-bootstrap';

const IndicatorsCumulativeTotalByMonths = () => {
  return (
    <Container className="vh-100 d-flex flex-column ">
      <h3>Показатели нарастающим итогом по месяцам</h3>
      <Row className="h-100">
        <iframe
          frameBorder="0"
          src="https://datalens.yandex/lec2kwa81lw29?_embedded=1&_no_controls=1&_theme=light"
        ></iframe>
      </Row>
    </Container>
  );
};

export default IndicatorsCumulativeTotalByMonths;
