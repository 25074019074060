import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import AppProvider from 'providers/AppProvider';
import { router } from 'routes';
import 'helpers/initFA';
import keycloak from 'keycloak';
import {ReactKeycloakProvider} from "@react-keycloak/web";

const container = document.getElementById('main');
const root = createRoot(container);

const keycloakInitOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
  flow: 'implicit'
};

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={keycloakInitOptions}
  >
    <React.StrictMode>
      <AppProvider>
        <RouterProvider router={router} />
      </AppProvider>
    </React.StrictMode>
  </ReactKeycloakProvider>
);
