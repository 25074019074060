import logo from './assets/logo.png';
import courses from './assets/courses.svg';
import money from './assets/money.svg';
import umniy from './assets/umniy.svg';

//todo передавать через пропсы доп пункты?

const getMenuItems = appConfig => {
  return [
    {
      link: ''.concat(appConfig.LMS_BASE_URL, '/dashboard'),
      img: logo,
      title: 'Главная страница'
    },
    {
      link: 'https://smart-inc.ru/#rec610729303',
      img: courses,
      title: 'Курсы'
    },
    {
      link: ''.concat(appConfig.LMS_BASE_URL, '/dashboard'),
      img: umniy,
      title: 'Учиться'
    },
    {
      link: ''.concat(appConfig.LMS_BASE_URL, '/orders'),
      img: money,
      title: 'Заказы'
    },
    {
      link: ''.concat(appConfig.LMS_BASE_URL, '/ebs-lan.html'),
      img: ''.concat(
        appConfig.LMS_BASE_URL,
        '/static/smart-design/images/ebslan/lan-logo.svg'
      ),
      title: 'Электронная библиотека Лань',
      style: { width: '35px' }
    }
  ];
};

export default getMenuItems;
