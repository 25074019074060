import React from 'react';
import { Container, Row } from 'react-bootstrap';

const RevenueByMonth = () => {
  return (
    <Container className="vh-100 d-flex flex-column ">
      <h3>Выручка по месяцам</h3>
      <Row className="h-100">
        <iframe
          frameBorder="0"
          src="https://datalens.yandex/azzbvbbptr3oy?_embedded=1&_no_controls=1&_theme=light"
        ></iframe>
      </Row>
    </Container>
  );
};

export default RevenueByMonth;
