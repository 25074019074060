import React from 'react';

export const Payment = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.38474 4.75391C4.1086 4.75391 3.88474 4.97776 3.88474 5.25391C3.88474 5.53005 4.1086 5.75391 4.38474 5.75391V4.75391ZM5.38474 5.25391L5.88261 5.20781C5.85879 4.95063 5.64302 4.75391 5.38474 4.75391V5.25391ZM13.3847 6.50006L13.8803 6.56634C13.8994 6.42345 13.8559 6.27926 13.761 6.17077C13.6661 6.06229 13.5289 6.00006 13.3847 6.00006V6.50006ZM5.50012 6.50006L5.00225 6.54616L5.50012 6.50006ZM13.0324 9.13443L12.5368 9.06815L13.0324 9.13443ZM4.38474 5.75391H5.38474V4.75391H4.38474V5.75391ZM7.21972 11.0756L11.8024 10.8036L11.7432 9.80539L7.16049 10.0773L7.21972 11.0756ZM13.528 9.20072L13.8803 6.56634L12.8891 6.43378L12.5368 9.06815L13.528 9.20072ZM4.88687 5.30001L5.00225 6.54616L5.99799 6.45396L5.88261 5.20781L4.88687 5.30001ZM5.00225 6.54616L5.2663 9.39791L6.26204 9.30571L5.99799 6.45396L5.00225 6.54616ZM13.3847 6.00006H5.50012V7.00006H13.3847V6.00006ZM11.8024 10.8036C12.6871 10.7511 13.4105 10.0791 13.528 9.20072L12.5368 9.06815C12.4828 9.47216 12.15 9.78124 11.7432 9.80539L11.8024 10.8036ZM7.16049 10.0773C6.70191 10.1045 6.3044 9.76314 6.26204 9.30571L5.2663 9.39791C5.35839 10.3925 6.22268 11.1347 7.21972 11.0756L7.16049 10.0773Z" />
      <rect
        x="7.04492"
        y="12.9746"
        width="0.00754513"
        height="0.00754513"
        strokeLinejoin="round"
      />
      <rect
        x="11.3262"
        y="12.9746"
        width="0.00754513"
        height="0.00754513"
        strokeLinejoin="round"
      />
    </svg>
  );
};
