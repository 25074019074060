import axios from 'axios';

export const fetchAccessTokenKeycloak = async () => {
  const params = new URLSearchParams();
  params.append('grant_type', 'client_credentials');
  params.append('client_id', process.env.REACT_APP_KK_CHAT_CLIENT_ID ?? '');
  params.append('client_secret', process.env.REACT_APP_KK_CHAT_CLIENT_SECRET ?? '');

  return await axios.post(
    `${process.env.REACT_APP_KEYCLOAK_URL}/realms/master/protocol/openid-connect/token`,
    params,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
      }
    }
  );
};
