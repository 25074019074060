import React, { useContext } from 'react';
import './SmartHeader.css';
// import {AppContext} from "@edx/frontend-platform/react";
import HeaderSearch from './header-elements/search/HeaderSearch';

const SmartHeader = ({ onSearch }) => {
  return (
    <header className="smart-header">
      <div className="main-header">
        <div className="header-content">
          <HeaderSearch onSearch={onSearch} />
        </div>
      </div>
    </header>
  );
};

export default SmartHeader;
