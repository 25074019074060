import axios from 'axios';
import {useQuery} from "@tanstack/react-query";

export const kinescopeApi = {
  useProjectsListQuery() {
    return useQuery({
      queryKey: ['kinescopeProjects'],
      queryFn: () =>
        axios.get(`https://api.kinescope.io/v1/projects?catalog_type=vod&per_page=100&page=1`,
          {
            headers: {
              authorization: `Bearer ${import.meta.env.REACT_APP_KINESCOPE_API_TOKEN}`
            }
          }),
      select: (response) => response.data
    })
  },
}