import React, {  useEffect, useState } from 'react';
import './HeaderSearch.css';
import HeaderRightPanel from '../right-panel/HeaderRightPanel';

const HeaderSearch = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  // useEffect( ()=>{
  //   // const username = keycloak.tokenParsed.preferred_username;
  //   console.log({keycloak})
  // }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onSearch(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, onSearch]);

  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };

  function showMenu() {
    const leftBar = document.querySelector('.menu');
    const burgerMob = document.querySelector('.burger-mob');
    leftBar.classList.toggle('showbar');
    burgerMob.classList.toggle('invert');
  }

  return (
    <div className="search-wrapper">
      {/*<img alt="Поиск" src={searchLogo} style={{marginLeft: 20}} />*/}
      <input
        type="search"
        placeholder="Поиск"
        className="smart-search"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <div className="burger-mob" onClick={showMenu}>
        ☰
      </div>

      <HeaderRightPanel />
    </div>
  );
};

export default HeaderSearch;
