import React from 'react';

export const Calls = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6447 7.36477C11.8672 9.88126 9.88126 11.8672 7.36477 12.6447C6.09836 13.036 5 11.9255 5 10.6C5 10.2686 5.26957 10.0038 5.5986 9.96454C6.02712 9.91341 6.439 9.80809 6.82719 9.65562L7.58683 10.4153C8.82235 9.824 9.82399 8.82235 10.4153 7.58683L9.65562 6.82719C9.80809 6.439 9.91341 6.02713 9.96454 5.5986C10.0038 5.26957 10.2686 5 10.6 5C11.9255 5 13.036 6.09836 12.6447 7.36477Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
